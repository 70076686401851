import axios from 'axios';
import { graphqlUrl, apiBaseUrl } from '../config/url';
// import store from './store'

const authInterceptor = (config) => {
  config.headers.common['x-om-token'] = localStorage.getItem('omEditorToken');
  return config;
};

const restClient = axios.create({
  baseURL: apiBaseUrl,
  withCredentials: true,
});

const gqlClient = axios.create({
  baseURL: graphqlUrl,
  withCredentials: true,
});
console.log('VUE_APP_API', apiBaseUrl);
gqlClient.gqlPost = (data) => {
  return gqlClient.post('', { query: data }, { headers: { 'Content-Type': 'application/json' } });
};

restClient.interceptors.request.use(authInterceptor);
gqlClient.interceptors.request.use(authInterceptor);
// axios.interceptors.request.eject(reqInterceptor) ?

export { restClient, gqlClient };
