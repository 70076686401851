<template lang="pug">
.container-fluid
  .row.mb-4
    .col
      om-heading(h1) {{ $t('whiteLabelSettings') }}
  .row
    .col-10.px-0
      form.d-flex.flex-column
        .row
          .col-6.d-flex
            .form-group.brand-box.flex-wrap.px-0.pt-0.pb-4.align-items-start
              label.col-12.mb-3.d-flex.align-items-center.font-size-0--875.font-weight-500 {{ $t('uploadLogo') }}
              .col-12.align-self-end
                .img-preview.img-preview-logo.mb-4(v-if="logoSrc")
                  img.cursor-pointer(
                    ref="logoImage"
                    :src="logoSrc"
                    @click.prevent="$refs.logoInput.click()"
                  )
                input(
                  ref="logoInput"
                  type="file"
                  style="display: none"
                  accept="image/jpeg|image/png|image/gif|text/svg"
                  @change="handleLogoFileSelect"
                )
                .row.no-gutters
                  om-button(secondary @click.prevent="$refs.logoInput.click()") {{ $t('browse') }}
                  om-button.ml-3(
                    secondary
                    v-if="logoFile || whiteLabel.customLogo"
                    @click.prevent="resetCustomLogo()"
                  ) {{ $t('reset') }}
          .col-6.d-flex
            .form-group.brand-box.flex-wrap.px-0.pt-0.pb-4.align-items-start
              label.col-12.mb-3.d-flex.align-items-center.font-size-0--875.font-weight-500 {{ $t('uploadSquaredLogo') }}
              .col-12.align-self-end
                .img-preview.img-preview-squared.mb-4(v-if="squaredSrc")
                  img.cursor-pointer(
                    ref="squaredImage"
                    :src="squaredSrc"
                    @click.prevent="$refs.squaredInput.click()"
                  )
                input(
                  ref="squaredInput"
                  type="file"
                  style="display: none"
                  accept="image/png"
                  @change="handleSquaredFileSelect"
                )
                .row.no-gutters
                  om-button(secondary @click.prevent="$refs.squaredInput.click()") {{ $t('browse') }}
                  om-button.ml-3(
                    secondary
                    v-if="squaredFile || whiteLabel.customSquared"
                    @click.prevent="resetCustomSquared()"
                  ) {{ $t('reset') }}
        .row
          .col-6
            .form-group.brand-box.px-0.pt-0.pb-3.flex-wrap
              .col-12.mb-4
                label.col-12.mb-2.d-flex.align-items-center.font-size-0--875.font-weight-500.pl-0 {{ $t('brandName') }}
                input.form-control.form-control-md(type="text" v-model="whiteLabel.brandName")
              .col-12
                label.col-12.mb-2.d-flex.align-items-center.font-size-0--875.font-weight-500.pl-0 {{ $t('domain') }}
                  om-tooltip.ml-2
                    div {{ $t('pleaseContact') }}
                input.form-control.form-control-md(
                  type="text"
                  disabled="disabled"
                  :value="whiteLabel.domainBase"
                )
          .col-12(style="flex-direction: column-reverse")
            .col-12.form-group.mt-2
              om-button(primary large @click.prevent="saveWhiteLabel") {{ $t('save') }}
</template>

<script>
  import GET_WHITELABEL_SETTINGS from '@/graphql/GetAgencyWhiteLabelSettings.gql';
  import UPDATE_WHITELABEL_SETTINGS from '@/graphql/UpdateWhiteLabelSettings.gql';
  import { isWhitelabelDomain } from '@/config/url';
  import OmLogo from '@/assets/om-logo.svg';
  import OmSquaredLogo from '@/assets/om-favicon.png';
  import WLSquaredLogo from '@/assets/cog-wheel.png';
  import { mapMutations } from 'vuex';
  import fileUploadMixin from '@/mixins/file-upload';

  const _clone = (v) => JSON.parse(JSON.stringify(v));

  export default {
    mixins: [fileUploadMixin],
    data() {
      return {
        logoFile: null,
        logoSrc: null,
        squaredFile: null,
        squaredSrc: null,
        whiteLabel: {
          customLogo: undefined,
          customSquared: undefined,
          brandName: '',
          domainBase: '',
        },
      };
    },
    watch: {
      'whiteLabel.customLogo': function (n) {
        if (!n) {
          this.logoSrc = OmLogo;
        } else {
          this.logoSrc = n;
        }
      },
      'whiteLabel.customSquared': function (n) {
        if (n) {
          this.squaredSrc = n;
        } else if (!isWhitelabelDomain()) {
          this.squaredSrc = OmSquaredLogo;
        } else {
          this.squaredSrc = WLSquaredLogo;
        }
      },
    },

    created() {
      this.$apollo.query({ query: GET_WHITELABEL_SETTINGS }).then(({ data }) => {
        this.whiteLabel = _clone(data.settings);
      });
    },

    methods: {
      ...mapMutations(['setWhiteLabelSettings']),
      handleLogoFileSelect() {
        const input = this.$refs.logoInput;
        if (input.files.length > 0) {
          this.logoFile = input.files[0];

          const reader = new FileReader();
          reader.onload = (e) => {
            this.logoSrc = e.target.result;
          };
          reader.readAsDataURL(this.logoFile);

          input.value = null;
        }
      },
      handleSquaredFileSelect() {
        const input = this.$refs.squaredInput;
        if (input.files.length > 0) {
          this.squaredFile = input.files[0];

          const reader = new FileReader();
          reader.onload = (e) => {
            this.squaredSrc = e.target.result;
          };
          reader.readAsDataURL(this.squaredFile);

          input.value = null;
        }
      },
      resetCustomLogo() {
        this.logoFile = null;
        this.logoSrc = OmLogo;
        this.whiteLabel.customLogo = null;
      },
      resetCustomSquared() {
        this.squaredFile = null;
        this.squaredSrc = isWhitelabelDomain() ? WLSquaredLogo : OmSquaredLogo;
        this.whiteLabel.customSquared = null;
      },
      async saveWhiteLabel() {
        const [logoUrl, squaredUrl] = await Promise.all([
          this.uploadFile(this.logoFile, 'upload/custom'),
          this.uploadFile(this.squaredFile, 'upload/custom'),
        ]);

        if (logoUrl) {
          this.whiteLabel.customLogo = logoUrl;
        }

        if (squaredUrl) {
          this.whiteLabel.customSquared = squaredUrl;
        }

        this.$apollo
          .mutate({
            mutation: UPDATE_WHITELABEL_SETTINGS,
            variables: {
              input: this.whiteLabel,
            },
          })
          .then(() => {
            this.$notify({
              type: 'success',
              title: 'Saved successfully!',
            });
            this.logoFile = null;
            this.squaredFile = null;
            this.setWhiteLabelSettings(_clone(this.whiteLabel));
          })
          .catch((e) => {
            console.log('e', e);
            this.$notify({
              type: 'error',
              title: 'Could not save!',
            });
          });
      },
    },
  };
</script>
