import { restClient as axios } from '@/editor/axios';

export default {
  methods: {
    async uploadFile(file, endpoint) {
      if (!file) {
        return null;
      }

      const data = new FormData();

      data.append('file', file);

      try {
        const resp = await axios.post(endpoint, data, {
          headers: { 'content-type': 'multipart/form-data' },
        });

        if (resp.status === 200 && resp.data && resp.data.url) {
          return resp.data.url;
        }
        throw new Error('Image url is missing from response');
      } catch (err) {
        const text =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : this.$t('notifications.errorWhileImageProcessing');
        console.error(err);
        this.$notify({
          type: 'error',
          text,
        });
      }
    },
  },
};
